<template>
  <div>
    <v-card class="pt-3" flat tile>
      <v-card-text>
        <v-row no-gutters row class="align-center grey--text text--darken-4">
          <div class="body-1 pr-2">
            <v-btn icon @click="show = !show">
              <v-icon>{{
                show ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }}</v-icon>
            </v-btn>
          </div>
          <v-col class="font-weight-medium subtitle-1 text-truncate">{{
            daily_menu.name | translate($i18n)
          }}</v-col>
          <div
            class="pt-1 pr-4 subtitle-2 text-right grey--text text--darken-4"
            style="min-width: 50px"
          >
            {{ daily_menu.total | currency_money }}
          </div>
          <div class="body-1 pl-2">
            <v-btn
              small
              class="btn-group"
              dark
              color="error darken-1"
              @click="$emit('remove-daily-menu', daily_menu)"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </div>
        </v-row>

        <v-expand-transition>
          <div v-show="show">
            <!-- Beverage -->
            <v-row class="ml-2">
              <v-col cols="12" class="pb-1 subtitle-1 text-uppercase">{{
                $t('cart.dailyMenu.beverage')
              }}</v-col>
              <v-col
                class="pt-1 subtitle-2 text-truncate grey--text text--darken-2"
                >{{ beverage.name | translate($i18n) }}</v-col
              >
              <div
                v-if="beverage.price"
                class="pt-1 pr-4 subtitle-2 text-right grey--text text--darken-4"
                style="min-width: 50px"
              >
                + {{ beverage.price | currency_money }}
              </div>
            </v-row>
            <!-- Beverage -->

            <!-- First dishes -->
            <v-row class="ml-2" v-if="first_dishes.length > 0">
              <v-col cols="12" class="pb-1 subtitle-1 text-uppercase">{{
                $t('cart.dailyMenu.firstDish')
              }}</v-col>
            </v-row>
            <v-row
              class="ml-2"
              v-for="first_dish in first_dishes"
              :key="daily_menu.unique_id + '_' + first_dish.id"
            >
              <v-col
                class="py-1 subtitle-2 text-truncate grey--text text--darken-2"
                >{{ first_dish.name | translate($i18n) }}</v-col
              >
              <div
                v-if="first_dish.price"
                class="pt-1 pr-4 subtitle-2 text-right grey--text text--darken-4"
                style="min-width: 50px"
              >
                + {{ first_dish.price | currency_money }}
              </div>
            </v-row>
            <!-- First dishes -->

            <!-- Second dishes -->
            <v-row class="ml-2" v-if="second_dishes.length > 0">
              <v-col cols="12" class="pb-1 subtitle-1 text-uppercase">{{
                $t('cart.dailyMenu.secondDish')
              }}</v-col>
            </v-row>
            <v-row
              class="ml-2"
              v-for="second_dish in second_dishes"
              :key="daily_menu.unique_id + '_' + second_dish.id"
            >
              <v-col
                class="py-1 subtitle-2 text-truncate grey--text text--darken-2"
                >{{ second_dish.name | translate($i18n) }}</v-col
              >
              <div
                v-if="second_dish.price"
                class="pt-1 pr-4 subtitle-2 text-right grey--text text--darken-4"
                style="min-width: 50px"
              >
                + {{ second_dish.price | currency_money }}
              </div>
            </v-row>
            <!-- Second dishes -->

            <!-- Dessert -->
            <v-row v-if="dessert" class="ml-2">
              <v-col cols="12" class="pb-1 subtitle-1 text-uppercase">{{
                $t('cart.dailyMenu.dessert')
              }}</v-col>
              <v-col
                class="pt-1 subtitle-2 text-truncate grey--text text--darken-2"
                >{{ dessert.name | translate($i18n) }}</v-col
              >
              <div
                v-if="dessert.price"
                class="pt-1 pr-4 subtitle-2 text-right grey--text text--darken-4"
                style="min-width: 50px"
              >
                + {{ dessert.price | currency_money }}
              </div>
            </v-row>
            <!-- Dessert -->

            <!-- Coffee -->
            <v-row v-if="coffee" class="ml-2">
              <v-col cols="12" class="pb-1 subtitle-1 text-uppercase">{{
                $t('cart.dailyMenu.coffee')
              }}</v-col>
              <v-col
                class="pt-1 subtitle-2 text-truncate grey--text text--darken-2"
                >{{ coffee.name | translate($i18n) }}</v-col
              >
              <div
                v-if="coffee.price"
                class="pt-1 pr-4 subtitle-2 text-right grey--text text--darken-4"
                style="min-width: 50px"
              >
                + {{ coffee.price | currency_money }}
              </div>
            </v-row>
            <!-- Coffee -->
          </div>
        </v-expand-transition>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    daily_menu: Object,
  },

  data: () => ({
    show: false,
  }),

  computed: {
    beverage() {
      return this.daily_menu.object_data.beverage;
    },
    first_dishes() {
      return this.daily_menu.object_data.first_dishes;
    },
    second_dishes() {
      return this.daily_menu.object_data.second_dishes;
    },
    coffee() {
      return this.daily_menu.object_data.coffee;
    },
    dessert() {
      return this.daily_menu.object_data.dessert;
    },
  },
};
</script>

<style></style>

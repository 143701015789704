<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">
          {{ $t('dialogs.errorUpdatedProductsTitle') }}
        </v-card-title>
        <v-card-text>
          {{ $t('dialogs.errorUpdatedProductsDetails') }}
          <v-list dense>
            <v-list-item v-for="item in items" :key="item.product.id">
              <v-list-item-content>
                <v-list-item-title>{{
                  item.product.name | translate($i18n)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="d in dailyMenus" :key="d.unique_id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ d.name | translate($i18n) }} -
                  {{ d.total | currency_money }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="primary" @click="cleanItems">
            {{ $t('dialogs.accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    errors: Object,
    dialog: Boolean,
  },

  data: () => ({}),

  computed: {
    ...mapGetters('cart', {
      cartItems: 'items',
      cartDailyMenus: 'daily_menus',
    }),

    items() {
      const keys = Object.keys(this.errors);
      return this.cartItems.filter((p, i) => {
        let found = false;

        keys.forEach(k => {
          // keys: products.0, products.4 etc...
          if ('products.' + i == k) {
            // We found a product
            found = true;
          }
        });

        return found;
      });
    },

    dailyMenus() {
      const keys = Object.keys(this.errors);
      return this.cartDailyMenus.filter((d, i) => {
        let found = false;
        keys.forEach(k => {
          // keys: daily_menus.0, daily_menus.4 etc...
          if ('daily_menus.' + i == k) {
            // We found a product
            found = true;
          }
        });

        return found;
      });
    },
  },

  methods: {
    cleanItems() {
      this.$emit('accept', {
        items: this.items,
        dailyMenus: this.dailyMenus,
      });
    },
  },
};
</script>

<style></style>

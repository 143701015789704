<template>
  <v-main>
    <v-container fluid>
      <!-- Shopping cart-->
      <h3 class="headline mb-4">
        {{ $t('cart.title') }}
        <small> - {{ $t('table') }} {{ tableNumber }}</small>
      </h3>

      <v-alert
        :value="items.length == 0 && daily_menus.length == 0"
        type="info"
        border="left"
        class="my-4"
        colored-border
        elevation="2"
        >{{ $t('cart.noItems') }}</v-alert
      >

      <div v-if="items.length > 0 || daily_menus.length > 0">
        <v-list two-line v-if="items.length > 0">
          <v-list-item v-for="item in items" :key="'item' + item.product.id">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                {{ item.product.name | translate($i18n) }}
              </v-list-item-title>
              <v-list-item-subtitle class="text--primary">
                {{ item.product.price | currency_money }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="price">
              <div class="body-2 font-weight-bold text--right">
                {{ (item.quantity * item.product.price) | currency_money }}
              </div>
            </v-list-item-action>

            <v-list-item-action class="cart-buttons">
              <v-list-item-action-text>
                <v-btn
                  small
                  class="btn-group"
                  dark
                  :color="item.quantity > 1 ? 'primary' : 'error darken-1'"
                  @click="remove(item.product)"
                >
                  <v-icon dark>
                    {{ item.quantity > 1 ? 'mdi-minus' : 'mdi-delete-outline' }}
                  </v-icon>
                </v-btn>
                <span class="black--text mx-1 font-weight-bold">
                  {{ item.quantity }}
                </span>
                <v-btn
                  small
                  class="btn-group"
                  dark
                  color="primary"
                  @click="add(item.product)"
                >
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>
        </v-list>

        <easy-serve-cart-daily-menus
          v-for="daily_menu in daily_menus"
          :key="'daily_menu_' + daily_menu.unique_id"
          :daily_menu="daily_menu"
          @remove-daily-menu="removeDailyMenu(daily_menu)"
        ></easy-serve-cart-daily-menus>

        <v-list two-line>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">{{
                $t('cart.totalPrice')
              }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="price">
              <div class="body-2 font-weight-bold text--right">
                {{ total | currency_money }}
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-card flat tile>
          <v-card-text>
            <v-textarea
              outlined
              :value="details"
              :label="$t('cart.textareaLabel')"
              :hint="$t('cart.textareaHint')"
              persistent-hint
              @input="storeDetails"
            ></v-textarea>
          </v-card-text>
        </v-card>
      </div>

      <v-alert
        :value="!isAuthorized"
        type="warning"
        border="left"
        class="my-4"
        colored-border
        elevation="2"
      >
        {{ $t('cart.authorizationDetails1') }}
        <code class="key">{{ key }}</code>

        <br />
        <small>{{ $t('cart.authorizationDetails2') }}</small>
      </v-alert>

      <v-btn
        block
        color="primary mb-3"
        v-if="!isAuthorized"
        @click="checkAuthorization"
      >
        <v-icon class="pr-2">mdi-account-convert</v-icon>
        {{ $t('cart.checkAuthorizationButton') }}
      </v-btn>
      <v-btn
        block
        color="primary"
        :disabled="!isAuthorized"
        v-if="items.length > 0 || daily_menus.length > 0"
        @click="placeOrder"
        >{{ $t('cart.placeOrderButton') }}</v-btn
      >

      <easy-serve-about-us></easy-serve-about-us>
    </v-container>

    <v-bottom-sheet v-model="removeDialog" persistent tile>
      <v-sheet class="text-center" height="200px">
        <v-btn class="mt-6 mr-4" @click="removeDailyMenuCancel">
          {{ $t('dialogs.cancel') }}
        </v-btn>
        <v-btn
          class="mt-6"
          color="error darken-1"
          @click="removeDailyMenuConfirmed"
        >
          {{ $t('dialogs.remove') }}
        </v-btn>
        <div class="py-3">{{ $t('dialogs.removeDailyMenu') }}</div>
      </v-sheet>
    </v-bottom-sheet>

    <easy-serve-products-updated
      :dialog="showInvalidProductsSheet"
      :errors="invalidProducts"
      @accept="cleanInvalidItems"
    ></easy-serve-products-updated>

    <!-- Loading screen -->
    <easy-serve-loading :loading="loading"></easy-serve-loading>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import EasyServeCartDailyMenus from './components/EasyServeCartDailyMenus';
import EasyServeAboutUs from '../components/EasyServeAboutUs';
import EasyServeLoading from '../components/EasyServeLoading';

import EasyServeProductsUpdated from '../components/EasyServeProductsUpdated';

export default {
  components: {
    EasyServeCartDailyMenus,
    EasyServeAboutUs,
    EasyServeLoading,
    EasyServeProductsUpdated,
  },

  data: () => ({
    removeDialog: false,
    daily_menu: null,

    error: null,

    invalidProducts: {},
    showInvalidProductsSheet: false,
  }),

  computed: {
    ...mapGetters('cart', {
      items: 'items',
      daily_menus: 'daily_menus',
      details: 'details',
      total: 'totalPrice',

      loading: 'loading',
    }),

    ...mapGetters('security', {
      isAuthorized: 'isAuthorized',
      key: 'key',
      tableNumber: 'table_number',
    }),
  },

  created() {},

  methods: {
    storeDetails(value) {
      this.$store.dispatch('cart/setDetails', value);
    },

    add(product) {
      this.$store.dispatch('cart/add', product);
    },

    remove(product) {
      this.$store.dispatch('cart/remove', product);
    },

    removeDailyMenu(daily_menu) {
      this.daily_menu = daily_menu;
      this.removeDialog = true;
    },
    removeDailyMenuConfirmed() {
      this.removeDialog = false;
      this.$store.dispatch('cart/removeDailyMenu', this.daily_menu);
    },
    removeDailyMenuCancel() {
      this.removeDialog = false;
      this.daily_menu = null;
    },

    checkAuthorization() {
      this.$store.dispatch('restaurant/check');
    },

    placeOrder() {
      if (!this.isAuthorized) return;

      this.$store
        .dispatch('cart/placeOrder')
        .then(response => {
          window.events.$emit('snackbar', response.data.message);
        })
        .catch(error => {
          if (error.response && error.response.status == 422) {
            // There are some changes in the menu, we must remove everything and reload all data.
            // console.log('Remove all data and reload from the server.');
            // console.log(error.response.data.errors);

            this.invalidProducts = error.response.data.errors;
            this.showInvalidProductsSheet = true;

            /**
              daily_menus.0: ["[BV0003] The daily_menus.0 has invalid data."]
              products.0: ["[PVNP] The products.0 has changed its name or price."]
              products.2: ["[PVNP] The products.2 has changed its name or price."]
             */
          }
          // Now, if we have a 422 error there are some problems with the products,
          // FIXME: Que hacemos en este caso? Se ha desactivado un producto que esta en el carrito de compra ...
          this.error = error;

          // If there is a 401 / 403 error we should get out
          // console.error(error);
        });
    },

    cleanInvalidItems(payload) {
      // console.log(payload);

      payload.items.forEach(item => {
        this.$store.dispatch('cart/remove', item.product);
      });

      payload.dailyMenus.forEach(dailyMenu => {
        this.$store.dispatch('cart/removeDailyMenu', dailyMenu);
      });

      this.invalidProducts = {};
      this.showInvalidProductsSheet = false;
    },
  },
};
</script>

<style></style>
